import React, { Fragment } from "react"
import { Styled, css } from "theme-ui"
import SiteFooter from "../../components/SiteFooter"

export default props => <div {...props}></div>

// const Footer = ({ socialLinks }) => (
//   <footer
//     css={css({
//       mt: 4,
//       pt: 3,
//     })}
//   >
//     © {new Date().getFullYear()}, Powered by
//     {` `}
//     <Styled.a href="https://www.gatsbyjs.org">Gatsby xyz</Styled.a>
//     {` `}&bull;{` `}
//     {socialLinks
//       ? socialLinks.map((platform, i, arr) => (
//           <Fragment key={platform.url}>
//             <Styled.a
//               href={platform.url}
//               target="_blank"
//               rel="noopener noreferrer"
//             >
//               {platform.name}
//             </Styled.a>
//             {arr.length - 1 !== i && (
//               <Fragment>
//                 {` `}&bull;{` `}
//               </Fragment>
//             )}
//           </Fragment>
//         ))
//       : null}
//   </footer>
// )
// export default Footer
